import { useState, useEffect } from 'react';
import FunMakerInvoice from './funmaker';
import GeneralInvoice from './general';
import { readFileAsText } from '../../../utils';
import { default as FileSelect } from '../../components/FileSelect';
import moment from 'moment';
import { ESTIMATE_DOC_TITLE, INVOICE_DOC_TITLE, RECEIPT_DOC_TITLE } from './general/constants';

const Invoice = () => {

  const [items, setItems] = useState({});

  const invoiceTitle = (type) => {
    switch (type) {
      case 'estimate': return ESTIMATE_DOC_TITLE;
      case 'invoice': return INVOICE_DOC_TITLE;
      case 'receipt': return RECEIPT_DOC_TITLE;
      default: return ESTIMATE_DOC_TITLE;
    }
  }

  // タイトルの変更
  useEffect(() => {
    if (items && Object.keys(items).length > 0) {
      const useDate = items.Y00_03 || items.publishDate || new Date();
      const formattedDate = moment(useDate).format('YYYYMMDD');
      const companyName = items.Y00_04 || items.customerName;
      const invoiceType = invoiceTitle(items.invoiceType);
      document.title = `${formattedDate}_${invoiceType}_${companyName}御中`;
    }
  }, [items]);

  // ファイル選択後の処理
  const handleSubmit = async (file) => {
    try {
      const jsonString = await readFileAsText(file);
      const json = JSON.parse(jsonString);
      setItems(json);
    } catch (error) {
      alert(error);
    }
  };

  const isFunMaker = items && 'Y00_01' in items;

  return (
    <>
      {!items || Object.keys(items).length === 0 ? (
        <FileSelect title="お見積書・ご請求書" onSubmit={handleSubmit} />
      ) : (
        <>
          { isFunMaker && <FunMakerInvoice data={items} />}
          {!isFunMaker && <GeneralInvoice  data={items} />}
        </>
      )}
    </>
  )
}
export default Invoice;
