
export const ESTIMATE_NEW_TITLE = 'FunMakerによる新規サイト制作に関する';
export const ESTIMATE_MIGRATE_TITLE = 'FunMakerへの既存サイト移転に関する';

// 費用: 共通
export const BASIC_SERVICE = '基本サービス';
export const BASIC_SERVICE_PRICE = 399800;
export const BASIC_SERVICE_MIGRATE_PRICE = 50000;
export const BASIC_SERVICE_INITIAL_SETTING = '初期設定（サーバの設定/FunMakerの設定/ドメイン購入）';
// export const BASIC_SERVICE_INITIAL_SETTING = '初期設定（FunMaker、サーバ、メールサーバの設定/ドメイン購入）';
export const BASIC_SERVICE_MAIL_SETTING = 'メール設定（メールサーバの作成、希望アドレスの登録）';
export const PRODUCTION_OPTION_LABEL = 'デザインオプション';
export const SIMPLE_OPTION_LABEL = 'シンプルオプション（Simple Option）';
export const SIMPLE_OPTION_PRICE = -100000;
export const DESIGN_OPTION_LABEL = 'デザインプラス（Design Plus）';
export const DESIGN_OPTION_PRICE =  100000;

// 費用: 新規
export const BASIC_SERVICE_NEW_DESIGN = '基本デザイン<sup>※１</sup>';
export const BASIC_SERVICE_CONTENTS = '基本コンテンツ反映（TOPページ＋4ページ）<sup>※２<sup>';
export const BASIC_SERVICE_NEW_PUBLISH = 'サイト公開<sup>※３</sup>';
export const OPTION_SERVICE_NEW_CSS = 'CSSを利用したデザイン調整の追加（20,000円/1P）<sup>※４</sup>';
export const OPTION_SERVICE_NEW_CONTENT = 'コンテンツ要素の反映作業（50,000円＋10,000円/1P）<sup>※５</sup>';

// 費用: 移転
export const BASIC_MIGRATE_SERVICE = "移転オプション";
export const BASIC_MIGRATE_SERVICE_CONTENTS = "クローラーによる移転（30ページまで）";
export const BASIC_MIGRATE_PAGE_NUMBER = 30;
export const BASIC_SERVICE_VOLUME_PRICE = 1000;
export const BASIC_SERVICE_MIGRATE_DESIGN = 'デザイン反映<sup>※１</sup>';
export const BASIC_SERVICE_MIGRATE_PUBLISH = 'リダイレクト設定・サイト公開・メールアドレスの登録<sup>※２</sup>';
export const BASIC_SERVICE_VOLUME = '規模による加算（30Pを超えるページで1,000円/10P）<sup>※３</sup>';
export const BASIC_SERVICE_MIGRATE_PAGE = 'ページの移転（30Pを超えるページで10,000円/10P）<sup>※４</sup>';
export const BASIC_SERVICE_MIGRATE_PAGE_PRICE = 10000;
export const OPTION_SERVICE_MIGRATE_CSS = 'CSSを利用したデザイン調整の追加（20,000円/1P）<sup>※５</sup>';
export const OPTION_SERVICE_MIGRATE_CONTENT = 'コンテンツ要素の反映作業（50,000円＋10,000円/1P）<sup>※６</sup>';


// 制作オプション費用
export const OPTION_SERVICE = '制作オプション';
export const OPTION_SERVICE_CSS_PRICE = 20000;
export const OPTION_SERVICE_CONTENTS_BASE_PRICE = 50000;
export const OPTION_SERVICE_CONTENTS_PER_PAGE_PRICE = 10000;
export const OPTION_SERVICE_CONTACT_FORM = 'お問い合わせフォーム反映';
export const OPTION_SERVICE_CONTACT_FORM_PRICE = 20000;
export const OPTION_SERVICE_DIRECTION = 'ディレクション費用（オプション作業費の2.5割）';
export const OPTION_SERVICE_DIRECTION_PRICE_RATE = 0.25;

// 特別値引キャンペーン
export const OPTION_CAMPAIGN = "特別値引";
export const OPTION_CAMPAIGN_PRICE = -200000;


// 注意事項
export const ESTIMATE_NOTE_TITLE = '特記事項';

// 注意事項:新規
export const ESTIMATE_NEW_BASIC_NOTE = `
<h4>▼※１：基本デザイン</h4>
<p>テンプレートを使用し、TOPページ、複数ページの一覧、各ページ共通要素に関して希望を元にFunMaker独自のサイトデザイン機能とCSSで調整。利用画像の撮影、作成、加工や、デザイン反映後の当社起因以外の再調整は別途料金が発生します。</p>
<h4>▼※２：基本コンテンツ反映（TOPページ＋4ページ）</h4>
<p>当社のヒナに対応した形でコンテンツ内容及び利用する画像等をご用意下さい。またページを作成するサービスはSEOの効果を発揮することを目的として提供しているため、必要ページを選択したり、他のページに置き換えたりすることはできません。</p>
<h4>▼※３：サイト公開</h4>
<p>サイトの公開は、希望を踏まえ最も近い当社の作業日に行い、詳細な日時の指定はできません。</p>
<h4>▼※４、※５：CSSを利用したデザイン調整の追加/コンテンツ要素の反映作業</h4>
<p>LPなどの作業量の多いページに関しては、追加の費用が発生する場合があります。また、コンテンツ要素の反映作業には、コンテンツ自体の作成やデザイン及びCSSを利用したデザインの反映は含まれません。</p>
`;

export const ESTIMATE_NEW_SIMPLE_NOTE = ESTIMATE_NEW_BASIC_NOTE.replace('FunMaker独自のサイトデザイン機能とCSS', 'FunMaker独自のサイトデザイン機能');
export const ESTIMATE_NEW_DESIGN_PLUS_NOTE = ESTIMATE_NEW_BASIC_NOTE.replace('希望を元にFunMaker独自のサイトデザイン機能とCSSで調整', 'に従いデザインを反映');

export const ESTIMATE_NEW_CAMPAIGN_NOTE = `
<h4>▼※６：特別値引</h4>
<p>申し込み後に送信されるメールに記載された、データや必要情報の提出期日や問い合わせ方法等を厳守して下さる方への特別な割引のため、対象条件を満たさないと判断された時点で適応は除外され割引分の費用が発生します。</p>
`;

// 注意事項:移転
export const ESTIMATE_MIGRATE_BASIC_NOTE = `
<h4>▼※１：デザイン反映</h4>
<p>テンプレートを使用し、TOPページ、複数ページの一覧、各ページ共通要素に関して希望を元にFunMaker独自のデザイン機能とCSSで調整。利用画像の撮影、作成、加工や、デザイン反映後の当社起因以外の再調整は別途料金が発生します。</p>
<h4>▼※２：リダイレクト設定・サイト公開・メールアドレスの登録</h4>
<p>URLの記述が規定に沿わない場合（日本語をそのまま利用しているなど）、リダイレクト設定が機能しない場合があります。また、サイトの公開は希望を踏まえ最も近い当社の作業日に行い、詳細な日時の指定はできません。</p>
<h4>▼※３、※４：規模による加算/ページの移転</h4>
<p>30Pを超える場合は、10Pにつき所定の料金（1P～10Pで1,000円/10,000円、11P～20Pで2,000円/20,000円）の追加が発生します。ページ移転は、コードを整理しSEO対策に最適化（不要タグの削除、不足タグや要素の追加、最適カセットへの適応）しますが、コンテンツの記述方法によって（tableを利用したレイアウト、テキスト等も画像で作成されたコンテンツなど）、別途追加費用が発生する場合があります（対象ページは別紙一覧を参照。）。</p>
<h4>▼※５、※６：CSSを利用したデザイン調整の追加/コンテンツ要素の反映作業</h4>
<p>LPなどの作業量の多いページに関しては、追加の費用が発生する場合があります。また、コンテンツ要素の反映作業には、コンテンツ自体の作成やデザイン及びCSSを利用したデザインの反映は含まれません。</p>
`;

export const ESTIMATE_MIGRATE_SIMPLE_NOTE = ESTIMATE_MIGRATE_BASIC_NOTE.replace('FunMaker独自のデザイン機能とCSS', 'FunMaker独自のデザイン機能');
export const ESTIMATE_MIGRATE_DESIGN_PLUS_NOTE = ESTIMATE_MIGRATE_BASIC_NOTE.replace('希望を元にFunMaker独自のデザイン機能とCSSで調整', 'に従いデザインを反映');

export const ESTIMATE_MIGRATE_CAMPAIGN_NOTE = `
<h4>▼※７：特別値引</h4>
<p>申し込み後に送信されるメールに記載された、データや必要情報の提出期日や問い合わせ方法等を厳守して下さる方への特別な割引のため、対象条件を満たさないと判断された時点で適応は除外され割引分の費用が発生します。</p>
`;
