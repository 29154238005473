import Header from './Header';
import Content from './Content';
import Note from './Note';

const Invoice = (props) => {

  const { details, type, note, orderNo } = props;

  return (
    <div className="invoice-page">
      <Header {...props} />
      <Content {...details} type={type} />
      <Note content={note} docType={type} orderNo={orderNo} />
    </div>
  );
} 
export default Invoice;
