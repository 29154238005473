import Invoice from '../../../components/invoice';

const InvoicePage = (props) => {

  const {
    orderNo,
    type,
    customerName,
    zip,
    address,
    title,
    dueDate,
    publisher,
    publishDate,
    items,
    note,
    subTotal,
  } = props;

  const invoiceProps = {
    type,
    orderNo,
    customerName,
    zip,
    address,
    title: title,
    dueDate,
    publisher,
    publishDate,
    note: note,
  };

  // 表示用のデータ
  const details = {
    subTotal: subTotal,
    data: items
  }

  return (
    <Invoice
      {...invoiceProps}
      details={details}
    />
  );
}
export default InvoicePage;
