import * as constant from './constants';
import Invoice from '../../../components/invoice';

const NewEstimate = (props) => {

  const {
    type,
    customerName,
    zip,
    address,
    publisher,
    publishDate,
    cssPage,
    optionPage,
    contactForm,
    campaign,
    plan,
    method,
    dueDate,
  } = props;

  const invoiceProps = {
    type,
    customerName,
    zip,
    address,
    title: constant.ESTIMATE_NEW_TITLE,
    publisher,
    publishDate,
    method,
    dueDate,
    noteTitle: constant.ESTIMATE_NOTE_TITLE,
  };

  // 制作オプション
  let productionOptionPrice = 0;
  let note = constant.ESTIMATE_NEW_BASIC_NOTE;
  const isSimpleOption = plan === 'simple_option';
  const isDesignOption = plan === 'design_plus';
  if (isSimpleOption) {
    productionOptionPrice += constant.SIMPLE_OPTION_PRICE;
    note = constant.ESTIMATE_NEW_SIMPLE_NOTE;
  } else if (isDesignOption) {
    productionOptionPrice += constant.DESIGN_OPTION_PRICE;
    note = constant.ESTIMATE_NEW_DESIGN_PLUS_NOTE;
  }
  invoiceProps.note = note; // + (campaign ? constant.ESTIMATE_MIGRATE_CAMPAIGN_NOTE : '');

  // オプション料金
  const cssPrice = constant.OPTION_SERVICE_CSS_PRICE * (cssPage || 0);
  const contentPrice = (optionPage || 0) > 0 ? constant.OPTION_SERVICE_CONTENTS_BASE_PRICE + constant.OPTION_SERVICE_CONTENTS_PER_PAGE_PRICE * optionPage : 0;
  const contactFormPrice = constant.OPTION_SERVICE_CONTACT_FORM_PRICE * (contactForm || 0);
  const directionPrice = (cssPrice + contentPrice + contactFormPrice) * constant.OPTION_SERVICE_DIRECTION_PRICE_RATE;

  // オプション金額サマリ
  const optionPrice = cssPrice + contentPrice + contactFormPrice;

  // キャンペーン料金
  const campaignPrice = campaign ? constant.OPTION_CAMPAIGN_PRICE : 0;

  // --- 表示用データ ---

  // 基本サービス
  const priceData = [{
    title: constant.BASIC_SERVICE,
    price: constant.BASIC_SERVICE_PRICE,
    items: [
      { title: constant.BASIC_SERVICE_INITIAL_SETTING },
      // { title: constant.BASIC_SERVICE_MAIL_SETTING },
      { title: constant.BASIC_SERVICE_NEW_DESIGN },
      { title: constant.BASIC_SERVICE_CONTENTS },
      { title: constant.BASIC_SERVICE_NEW_PUBLISH }
    ]
  }];

  // デザインオプション
  if (productionOptionPrice !== 0) {
    const productionOptionItems = [];
    if (isSimpleOption) {
      productionOptionItems.push({ title: constant.SIMPLE_OPTION_LABEL, num: 1, price: constant.SIMPLE_OPTION_PRICE })
    }
    if (isDesignOption) {
      productionOptionItems.push({ title: constant.DESIGN_OPTION_LABEL, num: 1, price: constant.DESIGN_OPTION_PRICE});
    }

    priceData.push({
      title: constant.PRODUCTION_OPTION_LABEL,
      num: '',
      price: productionOptionPrice,
      items: productionOptionItems,
    });
  }

  // 制作オプション
  const itemOptions = [
    {
      title: constant.OPTION_SERVICE_NEW_CSS,
      num: cssPage,
      unit: constant.OPTION_SERVICE_CSS_PRICE,
      price: cssPrice
    },
    {
      title: constant.OPTION_SERVICE_NEW_CONTENT,
      num: optionPage,
      unit: constant.OPTION_SERVICE_CONTENTS_PER_PAGE_PRICE,
      price: contentPrice
    },
    {
      title: constant.OPTION_SERVICE_CONTACT_FORM,
      num: contactForm,
      unit: constant.OPTION_SERVICE_CONTACT_FORM_PRICE,
      price: contactFormPrice
    },
  ]

  priceData.push({
    title: constant.OPTION_SERVICE,
    price: optionPrice,
    items: itemOptions
  });

  priceData.push({
    title: constant.OPTION_SERVICE_DIRECTION,
    num: directionPrice > 0 ? '' : '-',
    price: directionPrice
  })

  if (campaign) {
    priceData.push({ title: constant.OPTION_CAMPAIGN, price: campaignPrice });
  }

  // 表示用のデータ
  const details = {
    subTotal: constant.BASIC_SERVICE_PRICE + productionOptionPrice + optionPrice + directionPrice + campaignPrice,
    data: priceData
  }

  return (
    <Invoice
      {...invoiceProps}
      details={details}
    />
  );
}
export default NewEstimate;