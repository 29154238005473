import React from 'react';
import ReactDOM from 'react-dom';
import App from './views/pages';

import './style/main.scss';
import './style/invoice.scss';

ReactDOM.render(
  <App />,
  document.getElementById('root')
);