import { Fragment } from "react";

const TABLE_ROW_NUMBER = 16;

const Content = ({ type, subTotal, data }) => {

  const tax = Math.round(subTotal * 0.1);
  const total = subTotal + tax;

  // タイトル
  const invoiceTitle = (type) => {
    switch (type) {
      case 'invoice': return 'ご請求';
      case 'estimate': return 'お見積';
      case 'receipt': return '受領';
      default: return 'お見積';
    }
  }

  let rowNum = 0;

  function localString(arg, prefix = '￥', showZero = false) {
    if (Number.isFinite(arg) && (arg !== 0 || (arg === 0 && showZero))) {
      return prefix + Math.round(arg).toLocaleString();
    } else if (arg === 0 && !showZero) {
      return '';
    } else {
      return arg;
    }
  }

  return (
    <div className="invoice-body">
      <div className="invoice-total">
        <span className="invoice-total-title">{invoiceTitle(type)}金額：</span>
        <span className="invoice-total-price">{localString(total)}</span>
        <span className="invoice-total-tax">(消費税込み)</span>
      </div>

      <table className={`invoice-table type-${type}`}>
        <colgroup>
          <col style={{ width: '5%' }} />
          <col style={{ width: '60%' }} />
          <col style={{ width: '6%' }} />
          <col style={{ width: '13%' }} />
          <col style={{ width: '16%' }} />
        </colgroup>
        <thead>
          <tr>
            <th>NO.</th>
            <th>内容</th>
            <th>数量</th>
            <th className="indent">単価</th>
            <th className="right-text">小計</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(data) && data.map((detail, no) => {

            // 空行
            if (!detail.title && !detail.num && !detail.unit) {
              return <></>
            }

            rowNum += 1;

            // データが存在する行
            return (
              <Fragment key={`invoice-table-no-${no}`}>
                <tr className="invoice-table-subtotal">
                  <td className="invoice-table-no">{no + 1}</td>
                  <td dangerouslySetInnerHTML={{ __html: detail.title }} />
                  <td className="center-text">{localString(detail.num, '')}</td>
                  <td className="right-text invoice-table-unit">{localString(detail.unit)}</td>
                  <td className="right-text invoice-table-price">{localString(detail.price, '￥', true)}</td>
                </tr>
                {Array.isArray(detail.items) && detail.items.map((item, idx) => {
                  rowNum += 1;
                  const rowClass = "invoice-detail-" + (idx % 2 === 0 ? 'even' : 'odd');
                  
                  if (!item.title && !item.num && !item.unit) {
                    return <tr key={`invoice-table-spacer-${no}-${idx}`} className={`${rowClass}`}><td className="invoice-table-no">&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                  }

                  return (
                    <tr key={`invoice-table-detail-${no}-${idx}`} className={`invoice-table-detail ${rowClass}`}>
                      <td></td>
                      <td className="indent" dangerouslySetInnerHTML={{ __html: item.title }} />
                      <td className="center-text">{localString(item.num, '', true)}</td>
                      <td className="right-align invoice-table-unit">{localString(item.unit)}</td>
                      <td className="right-align invoice-table-price">{localString(item.price, '￥', true)}</td>
                    </tr>
                  );
                })}
              </Fragment>
            )
          })}
          {(() => {
            const items = [];
            const loop = TABLE_ROW_NUMBER - rowNum;

            if (loop > 0) {
              for (let i = 0; i < loop; i++) {
                const rowClass = "invoice-detail-" + (i % 2 === 0 ? 'even' : 'odd');
                items.push(<tr key={`invoice-table-spacer-${i}`} className={`${rowClass}`}><td className="invoice-table-no">&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>);
              }
            }
            return <>{items}</>;
          })()}
        </tbody>
        <tfoot>
          <tr className="invoice-table-footer-first-row">
            <td className="invoice-table-spacer" rowSpan="3" colSpan="3"></td>
            <td className="center-text left-border">小計（10%対象）</td>
            <td className="right-text">{localString(subTotal)}</td>
          </tr>
          <tr>
            <td className="center-text left-border">消費税（10%）</td>
            <td className="right-text">{localString(tax)}</td>
          </tr>
          <tr className="invoice-table-total">
            <td className="center-text last-row left-border">総計</td>
            <td className="right-text last-row">{localString(total)}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}
export default Content;