import { default as NewEstimate } from './New';
import { useParams } from 'react-router-dom';

const ESTIMATE_NEW = 'new';
const INVOICE_TYPE = 'estimate';

// お見積書
const Format = () => {

  const params = useParams();
  const props = defaultProps(params.plan, params.method);

  return (
    <NewEstimate
      {...props}
      type={INVOICE_TYPE}
    />
  );
}
export default Format;

function defaultProps(plan = 'basic_plan', method = 'handing') {
  return {
    estimateType: ESTIMATE_NEW,
    customerName: 'FORMAT CUSTOMER',
    publisher: '',
    publishDate: '',
    plan: plan,
    method: method,

    migrateVolume: 0,
    migratePages: 0,
    cssPage: 0,
    optionPage: 0,
    contactForm: 0,
    campaign: 0,
    dueDate: '',
  };
}
