import * as constant from './constants';
import Invoice from '../../../components/invoice';

const MigrateEstimate = (props) => {

  const {
    type,
    customerName,
    zip,
    address,
    publisher,
    publishDate,
    migrateVolume,
    migratePages,
    cssPage,
    optionPage,
    contactForm,
    campaign,
    plan,
    dueDate,
  } = props;

  const invoiceProps = {
    type,
    customerName,
    zip,
    address,
    title: constant.ESTIMATE_MIGRATE_TITLE,
    publisher,
    publishDate,
    dueDate,
  };

  // 基本サービス料金
  const basicPrice = constant.BASIC_SERVICE_PRICE;

  // 制作オプション
  let designOptionPrice = 0;
  let note = constant.ESTIMATE_MIGRATE_BASIC_NOTE;
  const isSimpleOption = plan === 'simple_option';
  const isDesignOption = plan === 'design_plus';
  if (isSimpleOption) {
    designOptionPrice += constant.SIMPLE_OPTION_PRICE;
    note = constant.ESTIMATE_MIGRATE_SIMPLE_NOTE;
  } else if (isDesignOption) {
    designOptionPrice += constant.DESIGN_OPTION_PRICE;
    note = constant.ESTIMATE_MIGRATE_DESIGN_PLUS_NOTE;
  }

  invoiceProps.note = note; // + (campaign ? constant.ESTIMATE_MIGRATE_CAMPAIGN_NOTE : '');


  // 移転費用
  const migrateVolumeUnit = Math.max(0, Math.ceil((migrateVolume - constant.BASIC_MIGRATE_PAGE_NUMBER) / 10));
  const migrateVolumePrice = migrateVolumeUnit * constant.BASIC_SERVICE_VOLUME_PRICE;

  const migratePageUnit = Math.max(0, Math.ceil((migratePages - constant.BASIC_MIGRATE_PAGE_NUMBER) / 10));
  const migratePagePrice = migratePageUnit * constant.BASIC_SERVICE_MIGRATE_PAGE_PRICE;

  const migratePrice = constant.BASIC_SERVICE_MIGRATE_PRICE + migrateVolumePrice + migratePagePrice


  // オプション料金
  const cssPrice = constant.OPTION_SERVICE_CSS_PRICE * (cssPage || 0);
  const contactFormPrice = constant.OPTION_SERVICE_CONTACT_FORM_PRICE * (contactForm || 0);
  const contentPrice = (optionPage || 0) > 0 ? constant.OPTION_SERVICE_CONTENTS_BASE_PRICE + constant.OPTION_SERVICE_CONTENTS_PER_PAGE_PRICE * optionPage : 0;
  const directionPrice = (cssPrice + contentPrice + contactFormPrice) * constant.OPTION_SERVICE_DIRECTION_PRICE_RATE;

  const optionPrice = cssPrice + contentPrice + contactFormPrice;

  // キャンペーン料金
  const campaignPrice = campaign ? constant.OPTION_CAMPAIGN_PRICE : 0;

  // --- 表示用データ ---

  // 基本サービス
  // 表示用のデータ
  const priceData = [
    {
      title: constant.BASIC_SERVICE,
      price: basicPrice,
      items: [
        { title: constant.BASIC_SERVICE_INITIAL_SETTING },
        // { title: constant.BASIC_SERVICE_MAIL_SETTING },
        { title: constant.BASIC_SERVICE_MIGRATE_DESIGN },
        { title: constant.BASIC_SERVICE_MIGRATE_PUBLISH }
      ]
    }
  ];

  const migratePriceData = {
    title: constant.BASIC_MIGRATE_SERVICE,
    price: migratePrice,
    items: [
      { title: constant.BASIC_MIGRATE_SERVICE_CONTENTS, num: 1, unit: constant.BASIC_SERVICE_MIGRATE_PRICE, price: constant.BASIC_SERVICE_MIGRATE_PRICE },
      { title: constant.BASIC_SERVICE_VOLUME, num: migrateVolumeUnit, unit: constant.BASIC_SERVICE_VOLUME_PRICE, price: migrateVolumePrice },
      { title: constant.BASIC_SERVICE_MIGRATE_PAGE, num: migratePageUnit, unit: constant.BASIC_SERVICE_MIGRATE_PAGE_PRICE, price: migratePagePrice },
    ]
  };
  priceData.push(migratePriceData);

  // オプション料金
  const optionItems = [
    {
      title: constant.OPTION_SERVICE_MIGRATE_CSS,
      num: cssPage,
      unit: constant.OPTION_SERVICE_CSS_PRICE,
      price: cssPrice
    },
    {
      title: constant.OPTION_SERVICE_MIGRATE_CONTENT,
      num: optionPage,
      unit: constant.OPTION_SERVICE_CONTENTS_PER_PAGE_PRICE,
      price: contentPrice
    },
    {
      title: constant.OPTION_SERVICE_CONTACT_FORM,
      num: contactForm,
      unit: constant.OPTION_SERVICE_CONTACT_FORM_PRICE,
      price: contactFormPrice
    },
  ];

  // デザインオプション
  if (designOptionPrice !== 0) {
    const designOptionItems = [];
    if (isSimpleOption) {
      designOptionItems.push({ title: constant.SIMPLE_OPTION_LABEL, num: 1, price: constant.SIMPLE_OPTION_PRICE })
    }
    if (isDesignOption) {
      designOptionItems.push({ title: constant.DESIGN_OPTION_LABEL, num: 1, price: constant.DESIGN_OPTION_PRICE});
    }
    
    priceData.push({
      title: constant.PRODUCTION_OPTION_LABEL,
      num: '',
      price: designOptionPrice,
      items: designOptionItems,
    });
  }

  // 制作オプション
  priceData.push({
    title: constant.OPTION_SERVICE,
    price: optionPrice,
    items: optionItems,
  });

  // ディレクション費用
  priceData.push({
    title: constant.OPTION_SERVICE_DIRECTION,
    num: directionPrice > 0 ? '' : '-',
    price: directionPrice
  })

  if (campaign) {
    priceData.push({ title: constant.OPTION_CAMPAIGN, price: campaignPrice });
  }

  const details = {
    subTotal: basicPrice + migratePrice + designOptionPrice +  optionPrice + directionPrice + campaignPrice,
    data: priceData
  }

  return (
    <Invoice
      {...invoiceProps}
      details={details}
    />
  );
}
export default MigrateEstimate;
