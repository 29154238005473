import { ESTIMATE_DOC_TITLE, INVOICE_DOC_TITLE, RECEIPT_DOC_TITLE } from '../../pages/invoice/general/constants';
import Logo from '../../../images/invoice/logo-blue.png';

// ヘッダー
const Header = ({ type, customerName, zip, address, title, publisher, publishDate, method, dueDate }) => {

  // 請求用のフォーマットにするかどうか
  const isInvoice = ['invoice', 'receipt'].indexOf(type) !== -1;
  const showPayMethod = type !== 'receipt';

  // タイトル
  const invoiceTitle = (type) => {
    switch (type) {
      case 'invoice': return INVOICE_DOC_TITLE;
      case 'estimate': return ESTIMATE_DOC_TITLE;
      case 'receipt': return RECEIPT_DOC_TITLE;
      default: return ESTIMATE_DOC_TITLE;
    }
  }

  // フォーマット時の対応
  const isFormat = customerName === 'FORMAT CUSTOMER';
  customerName = isFormat ? '' : customerName;

  // 決済関連の情報
  const payMethod = isInvoice ? '指定口座へ振込み' : '相談の上決定';
  const dueType = isInvoice ? '決済期限' : '有効期限';
  const formatOption = {
    era: 'long',
    year: 'numeric',
    month: 'narrow',
    day: 'numeric',
  };

  // お客様への提示
  const hideDate = isFormat && method === 'mail';

  // 有効期限
  let dueDateFormat = dueDate ? new Intl.DateTimeFormat('ja-JP-u-ca-japanese', formatOption).format(new Date(dueDate)) : '右記載の発行日より1ヶ月';
  const pubDate = publishDate ? new Date(publishDate) : new Date();
  let publishDateFormat = new Intl.DateTimeFormat('ja-JP-u-ca-japanese', formatOption).format(new Date(pubDate));

  // フォーマット出力時
  if (isFormat) {
    dueDateFormat = method === 'handing' ? 'お見積書お渡し日より1ヶ月' : '本お見積書添付のメール送信日より1ヶ月';
    publishDateFormat = '';
  }

  const customerNameClass = customerName ? 'customer-name invoice-row' : 'customer-name invoice-row empty-row';
  const publisherClass = (publisher || publishDateFormat || !hideDate) ? 'publisher' : 'publisher empty-rows';
  const registNo = isInvoice ? (<>登録番号： T5012401024349<br/></>) : <></>;

  return (
    <div className="header">
      <div className="header-left">
        <h1>{invoiceTitle(type)}</h1>

        <div className="customer-info">
          <dl className={customerNameClass}><dt>{customerName}</dt><dd>御中</dd></dl>
          <ul className="customer-address">
            <li>{zip ? `〒${zip}` : ''}</li>
            <li>{address}</li>
          </ul>
        </div>

        <div className="invoice-header">
          <dl className="invoice-title invoice-row"><dt>{title}</dt><dd>{invoiceTitle(type)}</dd></dl>
          {showPayMethod && <dl className="invoice-method">
            <dt><span className="monospace">決済方法</span>：</dt><dd>{payMethod}</dd>
            <dt><span className="monospace">{dueType}</span>：</dt><dd>{dueDateFormat}</dd>
          </dl>}
        </div>
      </div>

      <div className="header-right">
        <img className="logo" src={Logo} alt="アンドバリュー株式会社のロゴ" />
        <div className="company-info">
          アンドバリュー株式会社<br />
          {registNo}
          〒180-0004<br />
          東京都武蔵野市吉祥寺本町2丁目13番1号<br />
          電話：03-5326-3634<br />
        </div>
        <div className={publisherClass}>
          <dl className="invoice-row"><dt>発行日</dt><dd>{publishDateFormat}</dd></dl>
          <dl className="invoice-row"><dt>担当者</dt><dd>{publisher}</dd></dl>
        </div>
      </div>
    </div>
  );
}
export default Header;
