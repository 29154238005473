import { ESTIMATE_NOTE_TITLE, ESTIMATE_NOTE, INVOICE_NOTE_TITLE, INVOICE_NOTE, RECEIPT_NOTE_TITLE, RECEIPT_NOTE } from '../../pages/invoice/general/constants';

const Note = ({ orderNo, docType = false, content }) => {

  // メモ欄のタイトル
  const invoiceNoteTitle = (type) => {
    switch (type) {
      case 'estimate': return ESTIMATE_NOTE_TITLE;
      case 'invoice': return INVOICE_NOTE_TITLE;
      case 'receipt': return RECEIPT_NOTE_TITLE;
      default: return ESTIMATE_NOTE_TITLE;
    }
  }

  const invoiceNote = (note, type) => {
    if (note) { return note; }
    switch (type) {
      case 'estimate': return ESTIMATE_NOTE;
      case 'invoice': return INVOICE_NOTE;
      case 'receipt': return RECEIPT_NOTE;
      default: return ESTIMATE_NOTE;
    }
  }

  const noteClassName = (docType) => {
    return docType === 'invoice' ? 'invoice-note-content short' : 'invoice-note-content';
  }

  // メモ欄の内容
  const memoText = invoiceNote(content, docType).replaceAll('\\n', '<br />');

  return (
    <div className="invoice-note">
      <h2>{invoiceNoteTitle(docType)}</h2>
      <div className={noteClassName(docType)}>
        <div className="invoice-note-content-inner" dangerouslySetInnerHTML={{ __html: memoText.replace('ORDER_NO', orderNo || "") }} />
      </div>
      {docType === 'invoice' ? 
        <>
          <p className="invoice-account-notice">
            お支払いは下記銀行口座へお振り込みまたは手渡しによりお渡し頂けますようお願いいたします。<br />
            また、振り込みの場合は手数料につきましては貴社ご負担にてあわせてお願い申し上げます。
          </p>
          <dl className="invoice-account-info">
            <dt>銀行名</dt><dd>みずほ銀行</dd>
            <dt>支店名</dt><dd>吉祥寺支店　（246）</dd>
            <dt>口座種別</dt><dd>普通口座</dd>
            <dt>口座番号</dt><dd>1521765</dd>
            <dt>口座名義</dt><dd>アンドバリュー株式会社　 ［ｱﾝﾄﾞﾊﾞﾘｭｰ(ｶ］</dd>
          </dl>
        </> : <></> }
    </div>
  );
}

export default Note;
