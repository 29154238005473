import React from 'react';
import { BrowserRouter, Route, Switch, NavLink } from 'react-router-dom';

import Report from './report';
import Invoice from './invoice';
import Format from './invoice/funmaker/Format';

// build時はnavの削除および[/]のComponentを変更
class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Invoice} />
          <Route exact path="/invoice" component={Invoice} />
          <Route exact path="/format/:plan/" component={Format} />
          <Route exact path="/format/:plan/:method" component={Format} />
        </Switch>
      </BrowserRouter>
    );
  };
}

export default App;