// 注意事項
export const DEFAULT_PUBLISHER = '本多 正太';
  
export const ESTIMATE_DOC_TITLE = 'お見積書';
export const ESTIMATE_NOTE_TITLE = '特記事項';
export const ESTIMATE_NOTE = ``;

export const INVOICE_DOC_TITLE = 'ご請求書';
export const INVOICE_NOTE_TITLE = 'ご連絡事項';
export const INVOICE_NOTE = `毎度お引き立ていただきまして、まことにありがとうございます。<br>※ お振込みの際にはお振込名義の前に、お申し込みの返信メールに記載されている注文番号（ORDER_NO）を入力して下さい。`;

export const RECEIPT_DOC_TITLE = '領収書';
export const RECEIPT_NOTE_TITLE = '補足内容';
export const RECEIPT_NOTE = `上記の金額正に受領いたしました。`;