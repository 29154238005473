import { default as Page } from '../../../../components/Page';
import { clicks } from '../../../../../utils';

const Page3 = (props) => {

  const { data } = props;

  const ranks = [];
  let rank = 1;
  while (true) {
    const idx = ('00' + rank).slice(-2);
    const key = `K01_${idx}`;
    if (key in data) {
      console.log(data);
      console.log(key);
      const keyword = data[key] || "　",
        searchRank = String(data[`K02_${idx}`] || '').replace('位', ''),
        volume = data[`K03_${idx}`] || 0;
      const clickCount = clicks(searchRank, volume);
      ranks.push(<tr key={`key-${idx}`}><td className="tleft">{keyword}</td><td className="tright">{searchRank}</td><td className="tright">{clickCount}</td></tr>);
    } else {
      break;
    }
    rank += 1;
  }


  const tableSearchRanking = (
    <table className="stripe-reverse">
      <colgroup>
        <col />
        <col className="value" />
        <col className="value" />
      </colgroup>
      <thead><tr><th>キーワード</th><th>検索順位</th><th>月間訪問数</th></tr></thead>
      <tbody>
        {ranks}
      </tbody>
    </table>
  );

  return (
    <Page
      id="seo-top-page-3"
      score={data.P03_11}
      content={tableSearchRanking}
      summaryTitle="検索ポテンシャル(補足)"
      summaryDescription={
        <p>
          現在のホームページで対策した場合に効率的に成果が見込めるキーワード群。正しく対策された場合は高いコンバージョンが期待でき、大きな集客効果を生む可能性が高い。<br />
          <br />
          このキーワードの対策が正しくされず、検索順位が低い場合は自身の集客効果を毀損し続けることになるのはもちろん、良客との接点を競合他社に奪われ続けることとなる。対策を進めていくことで、Web集客が最適化される。
          <br />
        </p>
      }
    />
  );
}
export default Page3;
