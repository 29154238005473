import { default as NewEstimate } from './New';
import { default as MigrateEstimate } from './Migrate';

const INVOICE_TYPE = 'estimate';

const ESTIMATE_NEW = 'new';
const ESTIMATE_MIGRATE = 'migrate';

// お見積書
const Estimate = ({ data }) => {

  const props = migrateProps(data);

  console.log(data);

  if (props.estimateType === ESTIMATE_MIGRATE) {
    return (
      <MigrateEstimate
        {...props}
        type={INVOICE_TYPE}
      />
    );
  } else {
    return (
      <NewEstimate
        {...props}
        type={INVOICE_TYPE}
      />
    );
  }
}
export default Estimate;

export function migrateProps(json) {

  if ('orderNo' in json) {
    return json;
  }

  const migratePages = json.Y00_05 + json.Y00_07;
  const isProdEstimate = window.location.href.indexOf('localhost') === -1;
  const estimateType = (isProdEstimate && json.Y00_02 === '新規') ? ESTIMATE_NEW : ESTIMATE_MIGRATE;
  const isCampaign = isProdEstimate && json.Y00_13;

  return {
    estimateType: estimateType,
    customerName: json.Y00_04,
    publisher: json.Y00_01,
    publishDate: json.Y00_03,
    migrateVolume: json.Y00_05 + json.Y00_06 + json.Y00_07 + json.Y00_08,
    migratePages: migratePages + (json.Y00_12 === 1 ? 0 : json.Y00_08),
    cssPage: json.Y00_09,
    optionPage: json.Y00_10,
    contactForm: json.Y00_11,
    campaign: isCampaign,
    plan: json.Y00_14,
    dueDate: json.Y00_15,
  };
}
