import * as React from 'react';

class FileSelect extends React.Component {

  handleShowReport = async () => {
    if (!this.file.files || !this.file.files[0]) {
      return;
    }
    this.props.onSubmit(this.file.files[0]);
  };

  render() {
    return (
      <div className="report-page select-file">
        <h2 className="select-file-title">{ this.props.title }の表示</h2>
        <input
          type="file"
          className="file"
          ref={(file) => (this.file = file)}
          accept="application/JSON"
        />
        <button onClick={this.handleShowReport}>{ this.props.title }を表示</button>
      </div>
    );
  }
}

export default FileSelect;