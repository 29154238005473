import { default as InvoicePage } from './InvoicePage';

// ご請求書
const General = ({ data }) => {
  return (
    <InvoicePage
      {...data}
      type={data.invoiceType}
    />
  );
}

export default General;
