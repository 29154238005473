
// クリック率
export const CLICK_RATES = [
  19.35, 15.09, 11.45, 8.68, 7.21,
  5.85, 4.63, 3.93, 3.35, 2.28,
  2.4, 2.15, 1.85, 1.8, 1.5,
  1.3, 1.25, 1.2, 1.05, 0.85,
  0.75, 0.68, 0.65,
];

// ファイルの読み込み
export function readFileAsText(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = () => reject(reader.error);
    reader.onload = () => resolve(reader.result || '');
    reader.readAsText(file);
  });
}

// ファイルの内容をオブジェクトの配列に変換
export function mapCSVToArray(csv) {
  return csv.split('\n').map((row) => row.split(','));
}

// 
export function codeEmph(keyword, text) {
  if (text.toLowerCase().indexOf(keyword.toLowerCase()) !== -1) {
    const partials = text.match(/^(.*)content="([^"]+)"(.*)$/);
    if (partials) {
      return (
        <>{partials[1]} content="<span className="red-text">{partials[2]}</span>"{partials[3]}</>
      );
    }
  }
  return text;
}

// クリック数を取得する
export function clicks(ranking, volume) {
  if (ranking === '圏外' || !/^\d+$/.test(ranking)) {
    return '-';
  } else {
    ranking = Number(ranking);
    const clickNum = (CLICK_RATES.length > ranking) ? Math.round(volume * CLICK_RATES[ranking - 1] / 10) / 10 : 0;
    return clickNum > 0 ? clickNum : '-';
  }
}